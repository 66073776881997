import { fetchLatestSlotDataBefore } from "@/app/lib/actions";
import { Scanner } from "@/app/lib/types";
import { useKindeAuth } from "@kinde-oss/kinde-auth-nextjs";
import React, { useEffect, useState } from "react";
import DonationModal from "@/components/donation-modal";
import { handleDonation } from "@/app/lib/actions";
import toast from "react-hot-toast";

interface ScannerModalProps {
  onClose: () => void;
  onSave: (scanner: Omit<Scanner, "id">) => Promise<string>;
}

const ScannerModal: React.FC<ScannerModalProps> = ({ onClose, onSave }) => {
  const [name, setName] = useState("");
  const [frequency, setFrequency] = useState(10);
  const [country, setCountry] = useState("Canada");
  const [visaType, setVisaType] = useState("H1B");
  const [city, setCity] = useState("Vancouver");
  const [isTCN, setIsTCN] = useState(visaType === "H1B" ? true : false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const email = useKindeAuth().user?.email || "";
  const id = useKindeAuth().user?.id || "";

  const [supportedCountries, setSupportedCountries] = useState<
    {
      name: string;
      cities: string[];
    }[]
  >([]);

  const [showDonationModal, setShowDonationModal] = useState(false);
  const [currentScannerId, setCurrentScannerId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const latestSlotData = await fetchLatestSlotDataBefore();
      // grab all unique countries from the latest slot data with their cities see the following example
      // [
      //   {
      //     name: "Canada",
      //     cities: ["Vancouver", "Calgary", "Toronto", "Ottawa"],
      //   },
      // ]

      const uniqueCountries = Array.from(
        new Set(latestSlotData.map((slot) => slot.country))
      );

      const countryWithCitiesList = uniqueCountries
        .map((country) => {
          const cities = latestSlotData
            .filter((slot) => slot.country === country)
            .map((slot) => slot.city)
            .sort();
          return { name: country, cities: Array.from(new Set(cities)) };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setSupportedCountries(countryWithCitiesList);
    };
    fetchData();
  }, []);

  const countries =
    supportedCountries.length !== 0
      ? supportedCountries
      : [
          {
            name: "Canada",
            cities: ["Vancouver", "Calgary", "Toronto", "Ottawa"],
          },
          {
            name: "Mexico",
            cities: [
              "Ciudad Juarez",
              "Guadalajara",
              "Hermosillo",
              "Matamoros",
              "Merida",
              "Monterrey",
              "Nogales",
              "Nuevo Laredo",
              "Tijuana",
              "Mexico City",
            ],
          },
          {
            name: "United Kingdom",
            cities: ["Belfast", "London"],
          },
        ];

  const isNameValid = name.trim() !== "";
  const isVisaTypeValid = ["H1B", "B", "F1", "H4"].includes(visaType);
  const areDatesValid =
    fromDate && toDate && new Date(fromDate) <= new Date(toDate);
  const isFormValid =
    isNameValid && areDatesValid && country && city && isVisaTypeValid;

  const handleSave = async () => {
    console.log("Save button clicked");
    setIsSubmitted(true);
    if (!isFormValid) {
      console.log("Form is invalid");
      return;
    }
    const newScanner: Omit<Scanner, "id"> = {
      userId: id,
      name,
      frequency_in_minutes: frequency,
      conditions: { country, city, isTCN, fromDate, toDate, type: visaType },
      email: email,
      status: "active",
      createdAt: new Date(),
    };
    try {
      console.log("Saving scanner...");
      const savedScannerId = await onSave(newScanner);
      console.log("Scanner saved with ID:", savedScannerId);
      setCurrentScannerId(savedScannerId);
      setShowDonationModal(true);
      console.log("Donation modal should show now");
    } catch (error) {
      console.error("Error saving scanner:", error);
      onClose();
    }
  };

  const handleDonate = async (amount: number) => {
    if (!currentScannerId) return;

    try {
      await handleDonation(amount, currentScannerId);
      toast.success(`Thanks for your intention to donate $${amount}!`, {
        duration: 4000,
        position: "top-center",
        style: {
          background: "#4CAF50",
          color: "#fff",
          padding: "16px",
          borderRadius: "8px",
        },
      });
      setShowDonationModal(false);
      onClose();
    } catch (error) {
      console.error("Error processing donation:", error);
    }
  };

  const handleAfterDonation = () => {
    setShowDonationModal(false);
    onClose(); // Close the scanner modal after donation modal is closed
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 sm:p-4 z-50">
        <div className="bg-white p-3 sm:p-4 rounded-lg w-full max-w-[90vw] sm:max-w-md max-h-[90vh] overflow-y-auto">
          <h2 className="text-lg sm:text-xl font-bold mb-3">
            Create New Scanner
          </h2>
          <div className="space-y-3">
            <div>
              <label
                htmlFor="name"
                className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
              >
                Scanner Name*
              </label>
              <input
                id="name"
                type="text"
                placeholder="Enter scanner name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm ${
                  isSubmitted && !isNameValid ? "border-red-500" : ""
                }`}
                required
              />
              {isSubmitted && !isNameValid && (
                <p className="text-red-500 text-xs mt-1">
                  Scanner name is required
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="visaType"
                className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
              >
                Visa Type*
              </label>
              <select
                id="visaType"
                value={visaType}
                onChange={(e) => {
                  // If visa type is H1B, set isTCN to true
                  if (e.target.value === "H1B") {
                    setIsTCN(true);
                  } else {
                    setIsTCN(false);
                  }
                  setVisaType(e.target.value);
                }}
                className="w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm"
              >
                <option value="H1B">H1B</option>
                <option value="B">B1/B2</option>
                <option value="F1">F1</option>
                <option value="H4">H4</option>
              </select>
            </div>

            <div className="flex space-x-2">
              <div className="flex-1">
                <label
                  htmlFor="fromDate"
                  className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
                >
                  US Visa Slot From Date
                </label>
                <input
                  id="fromDate"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className={`w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm ${
                    isSubmitted && !fromDate ? "border-red-500" : ""
                  }`}
                  required
                />
              </div>
              <div className="flex-1">
                <label
                  htmlFor="toDate"
                  className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
                >
                  US Visa Slot To Date
                </label>
                <input
                  id="toDate"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className={`w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm ${
                    isSubmitted && !toDate ? "border-red-500" : ""
                  }`}
                  required
                />
              </div>
            </div>
            {isSubmitted && !areDatesValid && (
              <p className="text-red-500 text-xs mt-1">
                Please enter valid date range
              </p>
            )}

            <div>
              <label
                htmlFor="frequency"
                className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
              >
                Scan Frequency
              </label>
              <select
                id="frequency"
                value={frequency}
                onChange={(e) => setFrequency(+e.target.value)}
                className="w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm"
              >
                <option value="10">Every 10 minutes</option>
                <option value="30">Every 30 minutes</option>
                <option value="60">Every 60 minutes</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="country"
                className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
              >
                Country
              </label>
              <select
                id="country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setCity("");
                }}
                className="w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm"
              >
                <option value="">Select Country</option>
                {countries.map((c) => (
                  <option key={c.name} value={c.name}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-xs sm:text-sm font-medium text-gray-700 mb-1"
              >
                City
              </label>
              <select
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full p-1.5 sm:p-2 border rounded text-xs sm:text-sm"
                disabled={!country}
                required
              >
                <option value="">Select City</option>
                {country &&
                  countries
                    .find((c) => c.name === country)
                    ?.cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
              </select>
            </div>

            <div className="flex items-center">
              <input
                id="tcn"
                type="checkbox"
                checked={isTCN}
                onChange={(e) => setIsTCN(e.target.checked)}
                className="mr-2 h-4 w-4"
              />
              <label
                htmlFor="tcn"
                className="text-xs sm:text-sm font-medium text-gray-700"
              >
                Third Country National (TCN)
              </label>
            </div>

            <div className="flex items-center">
              <label
                htmlFor="tcn"
                className="text-xs sm:text-sm font-medium text-gray-700"
              >
                Your Email: <b>{useKindeAuth().user?.email}</b>
              </label>
            </div>
          </div>

          <div className="flex justify-end mt-4 space-x-2">
            <button
              className="bg-gray-300 px-3 py-1.5 rounded text-xs sm:text-sm hover:bg-gray-400 transition duration-300"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`px-3 py-1.5 rounded text-xs sm:text-sm transition duration-300 ${
                isFormValid
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-blue-300 text-white cursor-not-allowed"
              }`}
              onClick={handleSave}
              disabled={!isFormValid}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <DonationModal
        isOpen={showDonationModal}
        onClose={handleAfterDonation}
        onDonate={handleDonate}
      />
    </>
  );
};

export default ScannerModal;
