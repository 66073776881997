import { Scanner } from "@/app/lib/types";
import React from "react";

interface ScannerListProps {
  scanners: Scanner[];
  onDelete: (scannerId: string) => void;
  onStatusUpdate: (scannerId: string, status: Scanner["status"]) => void;
}

const ScannerList: React.FC<ScannerListProps> = ({ scanners, onDelete }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4">Your Scanners</h2>
      {scanners.length === 0 ? (
        <p className="text-gray-500 text-sm">No scanners created yet.</p>
      ) : (
        scanners.map((scanner) => (
          <div
            key={scanner.id}
            className="bg-white shadow rounded-lg overflow-hidden"
          >
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {scanner.name}
              </h3>

              <div className="space-y-3 text-sm">
                <ScannerField
                  label="Frequency"
                  value={`Every ${scanner.frequency_in_minutes} minutes`}
                  tooltip="How often this scanner checks for new slots"
                />

                <div>
                  <h4 className="font-medium text-gray-700 mb-1">
                    Conditions:
                  </h4>
                  <ul className="list-disc list-inside pl-2 space-y-1 text-gray-600">
                    <li>Country: {scanner.conditions.country || "Any"}</li>
                    <li>City: {scanner.conditions.city || "Any"}</li>
                    <li>Visa Type: {scanner.conditions.type || "Any"}</li>
                    <li>
                      TCN (Third Country National):{" "}
                      {scanner.conditions.isTCN ? "Yes" : "No"}
                    </li>
                    <li>
                      Date Range:{" "}
                      {scanner.conditions.fromDate &&
                      scanner.conditions.toDate ? (
                        <>
                          {scanner.conditions.fromDate} to{" "}
                          {scanner.conditions.toDate}
                        </>
                      ) : (
                        "Any"
                      )}
                    </li>
                  </ul>
                </div>
                {scanner.createdAt && (
                  <ScannerField
                    label="Created At"
                    value={new Date(scanner.createdAt).toLocaleString()}
                    tooltip="When this scanner was created"
                  />
                )}
                <div>
                  Email: <b>{scanner.email}</b>{" "}
                </div>

                <ScannerField
                  label="Status"
                  value={scanner.status}
                  valueClassName={getStatusColor(scanner.status)}
                  tooltip="Current state of the scanner"
                />
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-wrap justify-end space-x-2 space-y-2 sm:space-y-0">
              <button
                className="bg-red-500 hover:bg-red-600 text-white text-xs font-medium py-1.5 px-3 rounded transition duration-150 ease-in-out"
                onClick={() => onDelete(scanner.id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

interface ScannerFieldProps {
  label: string;
  value: string;
  valueClassName?: string;
  tooltip?: string;
}

const ScannerField: React.FC<ScannerFieldProps> = ({
  label,
  value,
  valueClassName,
  tooltip,
}) => (
  <div className="flex flex-col">
    <span className="text-gray-500 mb-1" title={tooltip}>
      {label}:
    </span>
    <span className={`font-medium ${valueClassName || "text-gray-700"}`}>
      {value}
    </span>
  </div>
);

function getStatusColor(status: Scanner["status"]): string {
  switch (status) {
    case "active":
      return "text-green-600";
    case "paused":
      return "text-yellow-600";
    case "delivered":
      return "text-blue-600";
    default:
      return "text-gray-600";
  }
}

export default ScannerList;
