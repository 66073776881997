"use client";

import { useState, useEffect, useCallback } from "react";
import { useKindeAuth, LoginLink } from "@kinde-oss/kinde-auth-nextjs";
import Link from "next/link";

import { Scanner } from "../lib/types";
import ScannerList from "@/components/scanner-list";
import ScannerModal from "@/components/scanner-modal";
import {
  deleteScanner,
  fetchUserScanners,
  saveScanner,
  updateScannerStatus,
} from "../lib/actions";
import ErrorMessage from "@/components/error-message";
import LoadingSpinner from "@/components/loading-spinner";

export default function ScannerPage() {
  const { user, isAuthenticated, isLoading } = useKindeAuth();
  const [scanners, setScanners] = useState<Scanner[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [showAllScanners, setShowAllScanners] = useState(false);

  const loadUserScanners = useCallback(async () => {
    if (!user?.email) return;
    try {
      const userScanners = await fetchUserScanners(user.id, user.email);
      setScanners(userScanners);
    } catch (err) {
      setError("Failed to load scanners. Please try again.");
      console.error(err);
    }
  }, [user?.email]);

  useEffect(() => {
    if (isAuthenticated) {
      setEmail(user?.email || "");
      loadUserScanners();
    }
  }, [isAuthenticated, loadUserScanners]);

  const handleDeleteScanner = async (scannerId: string) => {
    try {
      await deleteScanner(scannerId);
      await loadUserScanners();
    } catch (err) {
      setError("Failed to delete scanner. Please try again.");
      console.error(err);
    }
  };

  const handleScannerStatusUpdate = async (
    scannerId: string,
    status: Scanner["status"]
  ) => {
    try {
      await updateScannerStatus(scannerId, status);
      await loadUserScanners();
    } catch (err) {
      setError("Failed to update scanner status. Please try again.");
      console.error(err);
    }
  };

  const handleSaveScanner = async (
    newScanner: Omit<Scanner, "id">
  ): Promise<string> => {
    try {
      const id = await saveScanner(newScanner);
      await loadUserScanners();
      return id;
    } catch (err) {
      setError("Failed to save scanner. Please try again.");
      console.error(err);
      throw err;
    }
  };

  const activeScanners = scanners.filter(
    (scanner) => scanner.status === "active"
  );
  const scannersToDisplay = showAllScanners ? scanners : activeScanners;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 text-center">
        <ErrorMessage message="Please log in to view this page." />
        <LoginLink className="mt-4 inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
          Log In
        </LoginLink>
      </div>
    );
  }

  if (!user?.email) {
    return (
      <ErrorMessage message="Something went wrong. Please try again later." />
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6 rounded-lg">
        <p className="font-semibold">
          Welcome back,{" "}
          <span className="text-blue-600">
            {`${user.given_name}(${email})` || email}
          </span>
          ! 👋
        </p>
        <p className="text-sm mt-1">
          We're glad to see you. Your US Visa Slot Scanners are ready for your
          review.
        </p>
        {email === "hengliu0919@gmail.com" && (
          <div className="mt-2 space-x-2">
            <Link
              href="/app/admin"
              className="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Go to Admin
            </Link>
            <Link
              href="/articles/admin"
              className="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Write Article
            </Link>
          </div>
        )}
      </div>

      <h1 className="text-3xl font-bold mb-6">US Visa Slot Scanners</h1>

      <div className="flex justify-between items-center mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsModalOpen(true)}
        >
          Create New Scanner
        </button>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="showAllScanners"
            checked={showAllScanners}
            onChange={(e) => setShowAllScanners(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="showAllScanners">Show All Scanners</label>
        </div>
      </div>

      {error && <ErrorMessage message={error} />}

      <ScannerList
        scanners={scannersToDisplay}
        onDelete={handleDeleteScanner}
        onStatusUpdate={handleScannerStatusUpdate}
      />

      {isModalOpen && (
        <ScannerModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveScanner}
        />
      )}
    </div>
  );
}
