import { Dialog } from "@headlessui/react";

interface DonationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDonate: (amount: number) => Promise<void>;
}

export default function DonationModal({
  isOpen,
  onClose,
  onDonate,
}: DonationModalProps) {
  console.log("DonationModal props:", { isOpen, onClose, onDonate });

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
          <Dialog.Title className="text-lg font-medium mb-4">
            Support Our Tool
          </Dialog.Title>
          <p className="mb-4">
            Help us keep this tool free and ad-free by making a small donation:
          </p>

          <div className="flex gap-4 justify-center mb-4">
            {[5, 10, 20].map((amount) => (
              <button
                key={amount}
                onClick={() => onDonate(amount)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                ${amount}
              </button>
            ))}
          </div>

          <button
            onClick={onClose}
            className="w-full px-4 py-2 text-gray-600 border rounded hover:bg-gray-100"
          >
            Maybe next time
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
